import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { CallToAction } from '@rsa-digital/evo-shared-components/commonTypes/links';
import React from 'react';
import large_curve from 'assets/large_curve.svg';
import mobile_curve from 'assets/mobile_curve.svg';
import {
  BottomSpacing,
  ContentContainer,
  CornerImage,
  CtaWrapper,
  Curve,
  Heading,
  ImageWrapper,
  MobileContainer,
  MobileContentWrapper,
  MobileCurve,
  MobileImage,
  MobileWrapper,
  StyledPrimaryCta,
  Subhead,
  Wrapper,
} from './styles';

export type HeroWithSideImageProps = {
  heading: string;
  subhead?: string;
  image?: Image;
  primaryCta?: CallToAction;
  bottomSpaceHeight?: number;
  addBackgroundColourToBottomSpace?: boolean;
};

const HeroCurved: React.FC<HeroWithSideImageProps> = ({
  heading,
  subhead,
  primaryCta,
  image,
  bottomSpaceHeight,
  addBackgroundColourToBottomSpace,
}) => {
  const showBottomSpacing = !!bottomSpaceHeight; // bottomSpaceHeight is defined and > 0

  const Content = (): JSX.Element => (
    <>
      <Heading>{heading}</Heading>
      {subhead && <Subhead html={subhead} />}
      {primaryCta && (
        <CtaWrapper>
          <StyledPrimaryCta background="dark" cta={primaryCta} />
        </CtaWrapper>
      )}
    </>
  );

  return (
    <Wrapper data-cy="HeroBannerCurved">
      <ContentContainer>
        <Content />
      </ContentContainer>
      <ImageWrapper>
        <CornerImage
          data-cy="CornerImage"
          style={{ backgroundImage: `url(${image?.url})` }}
        />
        <Curve alt="Curve" src={large_curve} />
        <MobileWrapper>
          <MobileImage
            data-cy="CornerImage"
            style={{ backgroundImage: `url(${image?.url})` }}
          />
          <MobileContainer>
            <MobileCurve alt="Mobile Curve" src={mobile_curve} />
            <MobileContentWrapper>
              <Content />
            </MobileContentWrapper>
          </MobileContainer>
        </MobileWrapper>
      </ImageWrapper>
      {showBottomSpacing && (
        <BottomSpacing
          data-cy="HeroCurvedBottomSpacing"
          spaceHeight={bottomSpaceHeight ?? 0}
          addBackgroundColour={!!addBackgroundColourToBottomSpace}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(HeroCurved);
